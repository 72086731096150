import { AppointmentNote } from "@/Modules/Models"

const date = new Date()
const dateFrom = new Date(date.getDate() - 7)
const dateTo = new Date()

export default [
  {
    label: "Patient",
    options: [
      {
        label: "Patient",
        selectedByDefault: true,
        queryType: "search",
        urlKey: "filter.patient",
        inputType: "text",
        props: {
          placeholder: "Filter by Patient name"
        }
      }
    ]
  },
  {
    label: "Facility",
    options: [
      {
        label: "Facility",
        queryType: "search",
        urlKey: "filter.location",
        inputType: "entity-select",
        props: {
          entity: "Location"
        }
      }
    ]
  },
  {
    label: "Appointment Date",
    options: [
      {
        label: "Appointment Date",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.createdAt",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Appointment Time Frame"
        }
      },
      {
        label: "By Recommendation",
        inputType: "select",
        queryType: "filter",
        urlKey: "filter.recommendation",
        props: {
          options: AppointmentNote.recommendationsValueList
        }
      }
    ]
  },
  {
    label: "EPC Claims",
    options: [
      {
        label: "EPC Claimed",
        queryType: "search",
        urlKey: "filter.epcClaimed",
        inputType: "select",
        props: {
          options: [
            {
              value: "yes",
              label: "Yes"
            },
            {
              value: "no",
              label: "No"
            }
          ]
        }
      }
    ]
  },
  {
    label: "General Practitioner",
    options: [
      {
        label: "General Practitioner",
        queryType: "search",
        urlKey: "filter.generalPractitioner",
        inputType: "entity-select",
        props: {
          entity: "GeneralPractitioner"
        }
      }
    ]
  },
]